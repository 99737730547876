import { SelectionChangedEvent } from "ag-grid-community";
import { RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";
import { INITIAL_REQUISITION_FORM_COL_DEF, transferRowData } from "presentation/constant/RequisitionForm/RequisitionFormColumnDefinition";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import RequisitionFormPrintModal from "../RequisitionFormPrintModal";
const RequisitionFormTablePanel: React.FC = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();
    let gridRef: any = useRef();
    //const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    /*useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        (INITIAL_REQUISITION_FORM_COL_DEF?.slice());

        if (!requisitionFormState.selectedRequisitionFormRows ||
            requisitionFormState.selectedRequisitionFormRows.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        //}
    })*/

    const handleRowDoubleClick = useCallback((reqForm: RequisitionFormEntity) => {
        requisitionFormVM.onRowDoubleClick(reqForm);
    }, [requisitionFormVM])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        //setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        requisitionFormVM.updateSelectedReqForms(selectedRows);
    }, [requisitionFormVM])

    const memoReqFormTable = useMemo(() => {

        return (
            <NbisTable
                id='requisition-form-table'
                isNewColumnSetting={true}
                columns={INITIAL_REQUISITION_FORM_COL_DEF?.slice()}
                data={transferRowData(requisitionFormState.requisitionForms ?? [])}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                rowSelection={"multiple"}
                handleSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, reqForm: RequisitionFormEntity) => handleRowDoubleClick(reqForm)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                ref={gridRef}
            />
        );
    }, [handleRowDoubleClick, handleSelectionChange, requisitionFormState.requisitionForms])

    return <><TableWrapper>{(requisitionFormState.isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoReqFormTable}</TableWrapper>
        {requisitionFormState.isShowPrintModal && <RequisitionFormPrintModal />}
    </>;
}

export default memo(RequisitionFormTablePanel);