import { EMPTY_REQUISITION_FORM_ENTITY, RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";
import { customDispatchEvent, E_Custom_Dispatch_Event, E_Type_Of_Event } from "helpers/Events";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const RequisitionFormTitleBar: React.FC = () => {
    const requisitionFormVM = useRequisitionFormVM();
    const REQ_FORM_CONSTANT = RequisitionFormConstant;
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();

    const { selectedRequisitionFormRows } = requisitionFormState;
    const { allowCreate, allowUpdate, allowDelete } = anaInfoState;
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);

    const handleSearch = useCallback(() => {
        requisitionFormVM.onSearchClick();
    }, [requisitionFormVM]);


    const handleAdd = useCallback(async () => {
        requisitionFormVM.onAdd();
    }, [requisitionFormVM]);

    const isDisableDelete = useCallback(() => {
        if (selectedRequisitionFormRows && selectedRequisitionFormRows.length > 0) {
            const reqForms = selectedRequisitionFormRows.find(reqForm => reqForm.state === "INV");

            return reqForms ? true : false;
        }

        return true;
    }, [selectedRequisitionFormRows]);

    const isDisableCreNote = useCallback(() => {
        if (!Array.isArray(selectedRequisitionFormRows) || selectedRequisitionFormRows.length === 0) {
            return true;
        }

        const tempreqFormNo = selectedRequisitionFormRows[0].reqFormNo;
        if (tempreqFormNo === undefined) {
            return true;
        }

        return selectedRequisitionFormRows?.some(row => row.reqFormNo !== tempreqFormNo || row.reqMode !== "AN");
    }, [selectedRequisitionFormRows]);

    const handleDelete = useCallback(async () => {
        setIsShowDelete(false);
        requisitionFormVM.onShowLoading();

        requisitionFormVM.onDelete(requisitionFormState.selectedRequisitionFormRows?.map(row => row.id)?.filter((id): id is number => id !== null)).then((data) => {
            if (data.success) {
                messageBarVM.showSuccess("Delete record successful.");
                requisitionFormVM.getRequisitionForms(requisitionFormState.searchCriteria).then(() => {
                    requisitionFormVM.onHideLoading();
                }).catch((error) => {
                    requisitionFormVM.onHideLoading();
                })
            } else {
                messageBarVM.showError(data.data ?? '')
                requisitionFormVM.onHideLoading();
            }
        }).catch((error) => {
            requisitionFormVM.onHideLoading();
            messageBarVM.showError(error.message)
        });
    }, [messageBarVM, requisitionFormState.searchCriteria, requisitionFormState.selectedRequisitionFormRows, requisitionFormVM]);

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleReprintClick = useCallback(() => {
        requisitionFormVM.onShowPrintModal();
    }, [requisitionFormVM]);

    const handleCreditNoteClick = () => {
        //let tempReqFormNo:string = '';
        let reqFormEty: RequisitionFormEntity = { ...EMPTY_REQUISITION_FORM_ENTITY };
        if (selectedRequisitionFormRows && selectedRequisitionFormRows.length > 0) {
            //tempReqFormNo = selectedRequisitionFormRows[0].reqFormNo;
            reqFormEty = selectedRequisitionFormRows[0];
        }

        if (reqFormEty && reqFormEty.id !== 0) {
            customDispatchEvent(E_Type_Of_Event.CREDIT_NOTE_MAINT_REDIRECT_EVENT,
                E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE,
                {
                    userName: anaInfoState.userName,
                    data: reqFormEty,
                });
        }
    }

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{REQ_FORM_CONSTANT.TITLE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowCreate) && <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
            {(allowUpdate || allowDelete) && <div className="add-seperator" />} 
            {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_REPRINT} size={'Small'} theme={'Secondary'} onClick={handleReprintClick}/>}           
            {(allowDelete) && <HPHButton disabled={isDisableDelete()} label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            {<HPHButton disabled={isDisableCreNote()} label={'Credit Note'} size={'Small'} theme={'Secondary'} onClick={handleCreditNoteClick} />}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${REQ_FORM_CONSTANT.TITLE}`}
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
        {requisitionFormState.forceRefresh && <></>}
    </Sidebarheader>
}

export default memo(RequisitionFormTitleBar);