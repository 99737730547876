import { SelectionChangedEvent } from "ag-grid-community";
import { RequisitionFormCustomerEntity } from "domain/entity/RequisitionForm/RequisitionFormCustomerEntity";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { INITIAL_REQUISITION_FORM_CUST_COL_DEF, transferRowData } from "presentation/constant/RequisitionForm/RequisitionFormCustomerColumnDefinition";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";

import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const RequisitionFormDetailTablePanel = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked()
    const requisitionFormVM = useRequisitionFormVM();
    const gridRef: any = useRef(null);

    const { isRead, editingEntity } = requisitionFormState.masterState;
    const { isSliderOpen, reqFormCusts, selectedReqFormCusts } = requisitionFormState.detailState;

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [isLoading] = useState(false);


    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        /*const columnDefs = (INITIAL_REQUISITION_FORM_CUST_COL_DEF?.slice());
        
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);*/
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        requisitionFormVM.updateSelectedReqFormCusts(selectedRows);
    }, [requisitionFormVM])

    const handleAddClick = useCallback(() => {
        requisitionFormVM.onReqFormCustAdd();
    }, [requisitionFormVM])

    const handleDelete = useCallback(async () => {
        requisitionFormVM.onReqFormCustDelete(selectedReqFormCusts);
    }, [requisitionFormVM, selectedReqFormCusts]);

    const isApproved = useCallback(() => {
        let flag = false;
        let counter = 0;

        if (editingEntity) {
            if (editingEntity.mgrs && editingEntity.mgrs.length > 0) {
                editingEntity.mgrs?.forEach(item => {
                    if (item.mgrName && item.confirmDatetime) {
                        counter += 1;
                    }
                })

                if (counter === editingEntity.mgrs.length) {
                    flag = true;
                }
            }
        }
        return flag;
    }, [editingEntity]);

    const isEnableCust = useCallback(() => {
        if (editingEntity.reqMode === "TT"
            || editingEntity.reqMode === "WN"
        ) {
            if (!isApproved()) {
                return true
            }
        }

        return false;
    }, [editingEntity.reqMode, isApproved]);

    const handleEdit = useCallback((reqFormCust: RequisitionFormCustomerEntity) => {
        if (editingEntity.reqMode === "AN" || isApproved()) {
            return;
        }

        requisitionFormVM.onReqFormCustEdit(reqFormCust);
    }, [editingEntity.reqMode, isApproved, requisitionFormVM]);

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];
        const isDisable = (!isRead && !isSliderOpen && isEnableCust()) ? false : true;

        //if (!isRead && isEnableCust()) {
        tableBtns.push({
            id: 'onRemoveButton',
            icon: 'Icon-trash',
            title: 'Remove',
            disable: isDisable
        })

        tableBtns.push({
            id: 'onAddButton',
            icon: 'Icon-add',
            title: 'Add',
            disable: isDisable
        })
        //}
        return tableBtns
    }, [isEnableCust, isRead, isSliderOpen])

    const memoReqFormDetailTable = useMemo(() => {
        return (
            <div id="myDiv">
                <NbisTable
                    id='requisition-form-detail-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_REQUISITION_FORM_CUST_COL_DEF?.slice()}
                    headerLabel={RequisitionFormConstant.Detail.REUISITION_FORM_CUST}
                    headerActionButtons={memoTableBtns}
                    data={transferRowData(reqFormCusts ?? [])}
                    showPaginator={false}
                    editable={false}
                    showAddIcon={false}
                    // onAddClick={handleAddClickMenu}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    isRowHighligted={true}
                    rowSelection={"multiple"}
                    handleSelectionChanged={handleSelectionChange}
                    onAddButton={handleAddClick}
                    onRemoveButton={handleDelete}
                    onRowDoubleClick={(e: any, row: RequisitionFormCustomerEntity) => handleEdit(row)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 500px)"
                    ref={gridRef}
                />
            </div>
        );
    }, [memoTableBtns, reqFormCusts, handleSelectionChange, handleAddClick, handleDelete, handleEdit])

    return <>
        <TableWrapper>
            {(isLoading || requisitionFormState.isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoReqFormDetailTable}
        </TableWrapper>
    </>;
}

export default memo(RequisitionFormDetailTablePanel);